import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import clsx from "clsx";
import Script from "next/script";
import React from "react";

import Router from "next/router";
import { config } from "../../config";
import { usePagingParams } from "../../hooks/usePagingParams";
import { Event } from "../../models/event";
import { LeftHeaderOverride, RightHeaderOverride } from "../../models/headerOverride";
import { SessionInfo } from "../../models/sessionInfo";
import { UserSessionInfo } from "../../models/userSessionInfo";
import { AllEventsContext } from "../../state/allEventsContext";
import { AppContext } from "../../state/appContext";
import { BasePageContext } from "../../state/basePageContext";
import { makeStyles } from "../../styles/makeStyles";
import { clientApiGet, clientApiPost } from "../../util/api";
import { getClientPublicToken, setClientPublicToken, setClientSessionToken } from "../../util/cookies";
import { delay } from "../../util/delay";
import { OrganizationEventsPerPage } from "../../util/paging";
import { handleErrorToast } from "../../util/toast";
import { createUrlWithDeferred } from "../../util/url";
import { CustomHead } from "../customHead";
import { Div100vh } from "../div100vh";
import { BasePageHeader, getHeaderHeight, getSmallHeaderHeight } from "./basePageHeader";

const useStyles = makeStyles()((theme) => ({
    fillContainerWithHeader: {
        marginTop: `calc(-1 * ${getHeaderHeight(theme)})`,
        paddingTop: getHeaderHeight(theme),
        "@media (max-width: 500px)": {
            marginTop: `calc(-1 * ${getSmallHeaderHeight(theme)})`,
            paddingTop: getSmallHeaderHeight(theme),
        },
    },
    loadingIndicator: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
    },
}));

interface ToastInfo {
    type: "info" | "error";
    message: string;
    loading?: boolean;
}

type Props = React.PropsWithChildren<{
    className?: string;
    pageTitle: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    fillContentToHeight?: boolean;
    hideHeader?: boolean;
    clearHeader?: boolean;
    loadFacebookSDK?: boolean;
    loadEntriSDK?: boolean;
    sessionInfo?: SessionInfo;
    userSessionInfo?: UserSessionInfo;
    leftHeaderOverride?: LeftHeaderOverride;
    rightHeaderOverride?: RightHeaderOverride;
    onLogout?: () => void;
}>;

export const BasePage: React.FC<Props> = React.memo(
    ({
        className,
        pageTitle,
        ogTitle,
        ogDescription,
        ogImage,
        fillContentToHeight = false,
        hideHeader = false,
        clearHeader = false,
        loadFacebookSDK = false,
        loadEntriSDK = false,
        sessionInfo,
        userSessionInfo,
        leftHeaderOverride,
        rightHeaderOverride,
        children,
        onLogout,
    }) => {
        const { classes } = useStyles();
        const { organization, resetUser, resetOrganization, resetOrganizations } = React.useContext(AppContext);

        const [toastInfo, setToastInfo] = React.useState<ToastInfo>();
        const [publicToken, setPublicToken] = React.useState<string>();

        const loadPublicToken = React.useCallback(async () => {
            const publicTokenCookie = getClientPublicToken();
            if (publicTokenCookie) {
                setPublicToken(publicTokenCookie);
                return;
            }

            try {
                const publicToken = await clientApiPost<string>("/public_token");

                if (publicToken) {
                    setClientPublicToken(publicToken);
                    setPublicToken(publicToken);
                }
            } catch {}
        }, []);

        React.useEffect(() => {
            if (!publicToken && !!userSessionInfo?.sessionToken) {
                loadPublicToken();
            }
        }, [publicToken, loadPublicToken, userSessionInfo]);

        React.useEffect(() => {
            // adding 100ms delay so that resetting session info calls happen after the mounting calls in _app.tsx
            delay(100).then(() => {
                if (!!sessionInfo) {
                    setClientSessionToken(sessionInfo.sessionToken);
                    if (!!sessionInfo.user) {
                        resetUser(sessionInfo.user);
                    }

                    if (!!sessionInfo.organization) {
                        resetOrganization(sessionInfo.organization);
                    }

                    if (!!sessionInfo.organizations) {
                        resetOrganizations(sessionInfo.organizations);
                    }
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        React.useEffect(() => {
            if (!!userSessionInfo) {
                setClientSessionToken(userSessionInfo.sessionToken);
                resetUser(userSessionInfo.user);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const errorToast = React.useCallback(
            (message: string, { loading } = { loading: false }) => setToastInfo({ message, type: "error", loading }),
            [],
        );
        const infoToast = React.useCallback(
            (message: string, { loading } = { loading: false }) => setToastInfo({ message, type: "info", loading }),
            [],
        );

        const {
            data: allEvents,
            setData: setAllEvents,
            isLoadingMore: isLoadingMoreAllEvents,
            setIsLoadingMore: setIsLoadingMoreAllEvents,
            isDoneLoadingMore: isDoneLoadingMoreAllEvents,
            setIsDoneLoadingMore: setIsDoneLoadingMoreAllEvents,
            pagesLoaded: allEventsPagesLoaded,
            setPagesLoaded: setAllEventsPagesLoaded,
        } = usePagingParams<Event>();

        const loadFirstPageAllEvents = React.useCallback(
            async (orgId: number) => {
                setIsLoadingMoreAllEvents(true);
                try {
                    const events = await clientApiGet<Event[]>(`/org/${orgId}/events`, {
                        args: {
                            page: 1,
                            per_page: OrganizationEventsPerPage,
                            state: "published",
                            order_future_first: true,
                            include_external: true,
                        },
                    });

                    if (events === undefined) {
                        Router.push(createUrlWithDeferred("/login"));
                        return;
                    }

                    setIsLoadingMoreAllEvents(false);
                    setAllEventsPagesLoaded(1);
                    setAllEvents(events);
                    setIsDoneLoadingMoreAllEvents(events.length < OrganizationEventsPerPage);
                } catch (error: any) {
                    setIsLoadingMoreAllEvents(false);
                    setIsDoneLoadingMoreAllEvents(true);
                }
            },
            [setAllEvents, setIsLoadingMoreAllEvents, setIsDoneLoadingMoreAllEvents, setAllEventsPagesLoaded],
        );

        const loadNextPageAllEvents = React.useCallback(async () => {
            if (!!organization?.id) {
                const page = allEventsPagesLoaded + 1;

                setIsLoadingMoreAllEvents(true);
                try {
                    const events = await clientApiGet<Event[]>(`/org/${organization.id}/events`, {
                        args: {
                            page,
                            per_page: OrganizationEventsPerPage,
                            state: "published",
                            order_future_first: true,
                            include_external: true,
                        },
                    });

                    if (events === undefined) {
                        Router.push(createUrlWithDeferred("/login"));
                        return;
                    }

                    setIsLoadingMoreAllEvents(false);
                    setAllEventsPagesLoaded(page);
                    setAllEvents([...allEvents, ...events]);
                    setIsDoneLoadingMoreAllEvents(events.length < OrganizationEventsPerPage);
                } catch (error: any) {
                    setIsLoadingMoreAllEvents(false);
                    setIsDoneLoadingMoreAllEvents(true);
                    handleErrorToast({ error, message: "Failed to load more events", errorToast });
                }
            }
        }, [
            allEventsPagesLoaded,
            allEvents,
            organization?.id,
            setAllEventsPagesLoaded,
            setIsDoneLoadingMoreAllEvents,
            setIsLoadingMoreAllEvents,
            setAllEvents,
            errorToast,
        ]);

        const canLoadMoreAllEvents = React.useMemo(
            () => !isLoadingMoreAllEvents && !isDoneLoadingMoreAllEvents,
            [isLoadingMoreAllEvents, isDoneLoadingMoreAllEvents],
        );

        React.useEffect(() => {
            if (organization?.id) {
                loadFirstPageAllEvents(organization.id);
            }
        }, [organization?.id, loadFirstPageAllEvents]);

        return (
            <>
                <CustomHead pageTitle={pageTitle} ogTitle={ogTitle} ogDescription={ogDescription} ogImage={ogImage} />
                {loadFacebookSDK && (
                    <Script
                        type="text/javascript"
                        src={config.env === "production" ? "/facebookSDK.js" : "/facebookSDKDev.js"}
                    />
                )}
                {loadEntriSDK && <Script type="text/javascript" src="https://cdn.goentri.com/entri.js" />}
                <Snackbar
                    open={!!toastInfo}
                    autoHideDuration={5_000}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    onClose={(_e, reason) => {
                        if (reason === "clickaway") {
                            return;
                        }

                        setToastInfo(undefined);
                    }}
                >
                    {toastInfo && (
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={toastInfo.type}
                            sx={{ width: "100%" }}
                            icon={false}
                        >
                            {toastInfo.loading && <CircularProgress className={classes.loadingIndicator} size={16} />}
                            {toastInfo.message}
                        </Alert>
                    )}
                </Snackbar>
                <BasePageContext.Provider value={{ publicToken, errorToast, infoToast }}>
                    <AllEventsContext.Provider
                        value={{
                            allEvents,
                            isLoadingMoreAllEvents,
                            isDoneLoadingMoreAllEvents,
                            allEventsPagesLoaded,
                            canLoadMoreAllEvents,
                            setAllEvents,
                            setIsDoneLoadingMoreAllEvents,
                            setIsLoadingMoreAllEvents,
                            setAllEventsPagesLoaded,
                            loadNextPageAllEvents,
                        }}
                    >
                        {!hideHeader && !!sessionInfo && (
                            <BasePageHeader
                                clear={clearHeader}
                                leftHeaderOverride={leftHeaderOverride}
                                rightHeaderOverride={rightHeaderOverride}
                                onLogout={onLogout}
                            />
                        )}
                        {fillContentToHeight ? (
                            <Div100vh
                                className={clsx(className, hideHeader ? undefined : classes.fillContainerWithHeader)}
                            >
                                {children}
                            </Div100vh>
                        ) : (
                            children
                        )}
                    </AllEventsContext.Provider>
                </BasePageContext.Provider>
            </>
        );
    },
);
